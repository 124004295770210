import React, { useMemo, useState, useEffect } from "react";
import oddsCoverter from "odds-converter";
import { useSelector } from "react-redux";
import { Dropdown } from "antd";
import {
  UnorderedListOutlined,
  FormOutlined,
  StopOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import * as oddslib from "oddslib";
import { useTheme } from "../../../hooks";
import styles from "./Market.module.less";
import betriversLogo from "../../../assets/betrivers.svg";
import draftkingsLogo from "../../../assets/draftkings.svg";
import fanduelLogo from "../../../assets/fanduel.svg";
import pointsbetLogo from "../../../assets/pointsbet.svg";
import kambiLogo from "../../../assets/kambi.svg";
import barstoolLogo from "../../../assets/espnbet.svg";
import fanaticsLogo from "../../../assets/fanatics.svg";
import hardrockLogo from "../../../assets/hardrock.svg";
import betfredLogo from "../../../assets/betfred.svg";
import circaLogo from "../../../assets/circa.svg";
import fliffLogo from "../../../assets/fliff.svg";
import williamhillLogo from "../../../assets/caesars.svg";
import pinnacleLogo from "../../../assets/pinnacle.svg";
import betmgmLogo from "../../../assets/betmgm.svg";
import config from "../../../config";

const { states } = config;
const stateMap = {};
states.forEach((state) => {
  stateMap[state.abbreviation] = state;
});

const logos = {
  betrivers: betriversLogo,
  barstool: barstoolLogo,
  betfred: betfredLogo,
  circa: circaLogo,
  fanatics: fanaticsLogo,
  fliff: fliffLogo,
  kambi: kambiLogo,
  fanduel: fanduelLogo,
  pointsbet: pointsbetLogo,
  williamhill: williamhillLogo,
  draftkings: draftkingsLogo,
  pinnacle: pinnacleLogo,
  betmgm: betmgmLogo,
  hardrock: hardrockLogo,
};

const linkTypeProperties = {
  game: {
    content: <ExportOutlined />,
    background: "transparent",
    border: "1px solid #1da57a88",
    color: "#1da57a88",
  },
  betslip: {
    content: <FormOutlined />,
    background: "#1da57a88",
    border: "none",
    color: "white",
  },
  multi: {
    content: <UnorderedListOutlined />,
    background: "transparent",
    border: "1px solid #1da57a88",
    color: "#1da57a88",
  },
  none: {
    content: <StopOutlined />,
    background: "lightgray",
    border: "none",
    color: "white",
  },
};

const formatOdds = (value) => {
  const americanOdds = oddsCoverter.decimal.toAmerican(value);
  const roundedOdds = Math.round(americanOdds);
  if (roundedOdds > 0) return `+${roundedOdds}`;
  return `${roundedOdds}`;
};

const LinkWrapper = ({
  outcome,
  children,
  link,
  links,
  onHideOutcome,
  onHideMarket,
  onHideGame,
}) => {
  const rightClickMenuItems = [
    {
      label: (
        <div
          onClick={() => {
            // put link in clipboard
            navigator.clipboard.writeText(link);
          }}
        >
          Copy Link
        </div>
      ),
      key: "copy-link",
    },
    {
      label: (
        <div
          onClick={() => {
            onHideOutcome(3 * 60 * 1000);
          }}
        >
          Hide Line (3 minutes)
        </div>
      ),
      key: "hide-line-3-mins",
    },
  ];

  if (onHideGame) {
    rightClickMenuItems.push({
      label: <div onClick={() => onHideGame()}>Hide Game</div>,
      key: "hide-game",
    });
  }

  if (onHideMarket) {
    rightClickMenuItems.push({
      label: <div onClick={() => onHideMarket()}>Hide Market</div>,
      key: "hide-market",
    });
  }

  if (link) {
    return (
      <Dropdown menu={{ items: rightClickMenuItems }} trigger={["contextMenu"]}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={link}
          style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.85)" }}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
        >
          {children}
        </a>
      </Dropdown>
    );
  }

  if (links && links !== link) {
    const items = Object.keys(links).map((key) => {
      const url = outcome.urls[key];
      return {
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={url}
            style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.85)" }}
          >
            {key}
          </a>
        ),
        key,
      };
    });
    return (
      <Dropdown menu={{ items: rightClickMenuItems }} trigger={["contextMenu"]}>
        <Dropdown menu={{ items }} trigger={["click"]}>
          <button className={styles.noStyle}>{children}</button>
        </Dropdown>
      </Dropdown>
    );
  }
  return (
    <Dropdown menu={{ items: rightClickMenuItems }} trigger={["contextMenu"]}>
      {children}
    </Dropdown>
  );
};

const OtherOddsContainer = ({
  odds,
  themeTokens,
  className,
  expectedValueCalcBooksMap,
}) => {
  return (
    <div
      className={className}
      style={{
        background: themeTokens.colorBgContainerDisabled,
        color: themeTokens.colorText,
        width: `${Math.ceil(odds.length / 2) * 60 + 8}px`,
      }}
    >
      <div className={styles.otherOdds}>
        {odds.map(([book, probability]) => {
          try {
            const moneyline = Math.round(
              oddslib.from("impliedProbability", probability).to("moneyline")
            );
            const displayMoneyline =
              moneyline > 0 ? `+${moneyline}` : moneyline;
            return (
              <div
                key={book}
                className={styles.otherBooksOdds}
                style={{
                  opacity:
                    expectedValueCalcBooksMap &&
                    !expectedValueCalcBooksMap[book]
                      ? 0.75
                      : 1,
                }}
              >
                <img
                  src={logos[book]}
                  alt={book}
                  className={styles.smallBookLogo}
                />
                <div className={styles.otherBooksOddsInfo}>
                  {displayMoneyline}
                </div>
              </div>
            );
          } catch (_) {
            return null;
          }
        })}
      </div>
    </div>
  );
};

const Odds = ({
  typeData,
  outcome,
  showExpectedValue,
  showOtherOdds,
  expectedValueCalcBooksMap,
  evCalculationMethod,
  moreExpectedValue,
  onHideOutcome,
  onHideMarket,
  onHideGame,
}) => {
  const themeTokens = useTheme();
  const user = useSelector((state) => state.user.user);
  const [isShowingOtherBooksOdds, setIsShowingOtherBooksOdds] = useState(
    showOtherOdds === "show"
  );

  useEffect(() => {
    setIsShowingOtherBooksOdds(showOtherOdds === "show");
  }, [showOtherOdds]);

  const expectedValueDisplay = useMemo(() => {
    if (!outcome?.expectedValues) return undefined;
    if (!evCalculationMethod) {
      return outcome?.expectedValues?.worstCase
        ? outcome.expectedValues.worstCase
        : undefined;
    }
    return outcome.expectedValues[evCalculationMethod];
  }, [evCalculationMethod, outcome]);

  const sortedFilteredOtherOdds = useMemo(() => {
    if (outcome.otherBooksProbability) {
      let sortable = [];
      for (let book in outcome.otherBooksProbability) {
        if (book !== outcome.book) {
          sortable.push([book, outcome.otherBooksProbability[book]]);
        }
      }

      sortable.sort(function (a, b) {
        return a[1] - b[1];
      });

      return sortable;
    }
  }, [outcome.otherBooksProbability, outcome.book]);

  const [link, links, linkType] = useMemo(() => {
    let linkType = "none";
    if (outcome.url && typeof outcome.url === "string") {
      let link;
      if (outcome.book === "betmgm") {
        if (user.state) {
          link = outcome.url.replace(
            ".{state}.",
            `.${user.state.toLowerCase()}.`
          );
          linkType = "game";
        } else {
          link = outcome.url.replace("sports.{state}.betmgm", "sports.betmgm");
          linkType = "game";
        }
      } else if (user.state && outcome.book === "williamhill") {
        if (!stateMap[user.state]?.czrDomain) {
          link = outcome.url.replace("/il/", `/${user.state.toLowerCase()}/`);
          linkType = "game";
        } else if (user.state) {
          link = outcome.url.replace(
            "https://sportsbook.caesars.com/us/il/bet/",
            `https://sportsbook.caesars.com/us/${user.state.toLowerCase()}/bet/`
          );
          linkType = "game";
        }
      } else if (!isMobile && user.state && outcome.book === "fanduel") {
        link = outcome.url.replace(
          "https://sportsbook.fanduel.com",
          `https://${user.state.toLowerCase()}.sportsbook.fanduel.com`
        );
        linkType = "game";
      } else {
        link = outcome.url;
        linkType = "game";
      }

      if (
        user.globalToolSettings &&
        user.globalToolSettings.useDirectBetslipLink &&
        outcome.directBetUrl &&
        outcome.directBetUrl.desktop
      ) {
        if (outcome.book === "williamhill") {
          if (!isMobile) {
            if (!user.state) {
              link = outcome.directBetUrl.desktop;
              linkType = "betslip";
            } else {
              if (!stateMap[user.state]?.czrDomain) {
                link = outcome.directBetUrl.desktop.replace(
                  "/il/",
                  `/${user.state.toLowerCase()}/`
                );
                linkType = "betslip";
              } else {
                link = outcome.directBetUrl.desktop.replace(
                  "https://sportsbook.caesars.com/us/il/bet/",
                  `https://sportsbook.caesars.com/us/${user.state.toLowerCase()}/bet/`
                );
                linkType = "betslip";
              }
            }
          } else {
            link = outcome.url;
            linkType = "game"; // TODO: CZR mobile does not support betslip
          }
        } else if (outcome.book === "fanduel" && outcome.directBetUrl) {
          const [baseUrl, queryString] =
            outcome.directBetUrl.desktop.split("?");

          // Split the query string into individual key-value pairs
          const queryParams = queryString.split("&");

          // Decode each key-value pair
          const decodedParams = queryParams.map((param) => {
            const [key, value] = param.split("=");
            return `${decodeURIComponent(key)}=${decodeURIComponent(value)}`;
          });

          // Join the decoded parameters back into a string
          const decodedQueryString = decodedParams.join("&");

          // Reconstruct the URL with the decoded query string
          link = `${baseUrl}?${decodedQueryString}`;
          linkType = "betslip";
          if (!isMobile && user.state) {
            link = link.replace(
              "https://sportsbook.fanduel.com",
              `https://${user.state.toLowerCase()}.sportsbook.fanduel.com`
            );
          }
        } else if (outcome.book === "draftkings" && outcome.directBetUrl) {
          link = isMobile
            ? outcome.directBetUrl.mobile
            : outcome.directBetUrl.desktop;
          linkType = "betslip";
        } else if (outcome.book === "betmgm" && outcome.directBetUrl) {
          link = isMobile
            ? outcome.directBetUrl.mobile
            : outcome.directBetUrl.desktop;
          linkType = "betslip";
          if (!isMobile) {
            if (user.state) {
              link = link.replace(
                "sports.{state}.betmgm",
                `sports.${user.state.toLowerCase()}.betmgm`
              );
            } else {
              link = link.replace("sports.{state}.betmgm", `sports.betmgm`);
            }
          }
        }
      }

      return [link, null, linkType];
    }

    if (outcome.urls && outcome.urls !== outcome.url) {
      return [null, outcome.urls, "multi"];
    }

    return [null, null, linkType];
  }, [outcome.url, outcome.urls, user, outcome.book, outcome.directBetUrl]);

  let { isMoneyline, isYesNo } = typeData;

  const style = { background: themeTokens.colorBgContainerDisabled };
  if (moreExpectedValue) {
    style.outline = `1.5px solid ${themeTokens.colorErrorActive}`;
  }

  const linkProperties = linkTypeProperties[linkType];

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {showOtherOdds === "toggle" && sortedFilteredOtherOdds.length > 0 && (
          <button
            style={{
              height: "100%",
              background: "transparent",
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => setIsShowingOtherBooksOdds(!isShowingOtherBooksOdds)}
          >
            <div className={styles.oddsContainer}>
              <div
                className={styles.odds}
                style={{
                  background: themeTokens.colorBgContainerDisabled,
                  color: themeTokens.colorText,
                }}
              >
                <UnorderedListOutlined />
              </div>
            </div>
          </button>
        )}
        {isShowingOtherBooksOdds && (
          <OtherOddsContainer
            className={styles.otherOddsContainer}
            odds={sortedFilteredOtherOdds}
            expectedValueCalcBooksMap={expectedValueCalcBooksMap}
            {...{ themeTokens }}
          />
        )}
        <LinkWrapper
          outcome={outcome}
          link={link}
          links={links}
          onHideOutcome={(length) => onHideOutcome(length)}
          onHideMarket={onHideMarket}
          onHideGame={onHideGame}
        >
          <div className={styles.oddsContainer}>
            <div className={styles.oddsAndLinkContainer} style={style}>
              <div className={styles.odds}>
                <img
                  src={logos[outcome.book]}
                  alt={outcome.book}
                  className={styles.bookLogo}
                />
                <div
                  className={styles.infoContainer}
                  style={{
                    background: themeTokens.colorBgContainerDisabled,
                    color: themeTokens.colorText,
                  }}
                >
                  {!isMoneyline && !isYesNo && (
                    <div className={styles.qualifier}>
                      {outcome.points}
                      {!outcome.isMainMarketOutcome && "*"}
                    </div>
                  )}
                  <span className={styles.info}>
                    {formatOdds(outcome.boostedOdds || outcome.odds)}
                  </span>
                </div>
              </div>
              <div
                className={styles.linkSymbolContainer}
                style={{
                  background: linkProperties.background,
                  color: linkProperties.color,
                  border: linkProperties.border,
                }}
              >
                {linkProperties.content}
              </div>
            </div>
            {showExpectedValue && expectedValueDisplay && (
              <div className={styles.expectedValue}>
                {(expectedValueDisplay * 100).toFixed(2)}%
              </div>
            )}
          </div>
        </LinkWrapper>
      </div>
      {isShowingOtherBooksOdds && (
        <OtherOddsContainer
          className={styles.otherOddsContainerSmall}
          odds={sortedFilteredOtherOdds}
          {...{ themeTokens }}
        />
      )}
    </div>
  );
};

export default Odds;
