import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchUser } from "../../../state/ducks/user";
import config from "../../../config";
import { createCheckoutSession, getUser } from "../../../api";
import getToken from "../../../utils/getToken";

const stripe = window.Stripe(config.stripeKey);

export default (WrappedComponent) => {
  return ({ ...props }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    const userStatus = useSelector((state) => state.user.status);
    const auth0 = useAuth0();

    useEffect(() => {
      const fetchUserEffect = async () => {
        const token = await getToken({ auth0 });
        dispatch(fetchUser({ token }));
      };

      fetchUserEffect();
      const intervalId = setInterval(async () => {
        try {
          const token = await getToken({ auth0 });
          const user = await getUser({ token });
          if (user.subscriptions.length === 0) {
            auth0.logout({ returnTo: window.location.origin });
          }
        } catch (error) {
          console.error(error);
        }
      }, 10 * 60 * 1000); // 10 minutes

      return () => clearInterval(intervalId); // Cleanup interval on unmount
    }, [auth0, dispatch]);

    const goToSignUp = async () => {
      const token = await getToken({ auth0 });
      const checkoutSession = await createCheckoutSession({ token });
      stripe.redirectToCheckout({ sessionId: checkoutSession.id });
    };

    if (user && user.subscriptions.length === 0) {
      goToSignUp();
      return null;
    }

    if (
      userStatus !== "succeeded" ||
      !user ||
      user.subscriptions.length === 0
    ) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };
};
