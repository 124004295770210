import React, { useState, useEffect, useMemo } from "react";
import { Dropdown, Checkbox } from "antd";
import { useTheme } from "../../../hooks";
import styles from "./Market.module.less";
import {
  PushpinOutlined,
  NotificationOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import Odds from "./Odds";

const buildCompetitorName = (
  isOverUnder,
  isYesNo,
  side,
  participants,
  outcomeNumber
) => {
  if (isOverUnder || isYesNo || side === "Draw") return side;
  if (side === "Home or Away")
    return `${participants[1]} or ${participants[0]}`;
  if (side === "Home or Draw") return `${participants[1]} or Draw`;
  if (side === "Away or Draw") return `${participants[0]} or Draw`;
  return participants[outcomeNumber];
};

const leageEmojiMap = {
  ncaa_basketball: "🏀",
  ncaa_football: "🏈",
};

const Market = ({
  player,
  typeData,
  event,
  bet0,
  bet1,
  largeNumber,
  smallNumber,
  outcomes,
  competitionName,
  sport,
  middle,
  onPin,
  onHide,
  onHideOutcome,
  onAlert,
  onWhoseWrongChange,
  betType,
  live,
  showExpectedValue,
  highlightColor,
  showOtherOdds,
  league,
  expectedValueCalcBooksMap,
  evCalculationMethod,
}) => {
  const themeTokens = useTheme();

  const leagueEmoji = useMemo(() => {
    return leageEmojiMap[league];
  }, [league]);

  const { participants } = event;
  const outcome0 = outcomes.find(
    (outcome) =>
      outcome.side === "Away (3 Way)" ||
      outcome.side === "Away or Draw" ||
      outcome.side === "Draw" ||
      outcome.side === "Away" ||
      outcome.side === "Over" ||
      outcome.side === "Yes"
  );
  const outcome1 = outcomes.find(
    (outcome) =>
      outcome.side === "Home (3 Way)" ||
      outcome.side === "Home or Draw" ||
      outcome.side === "Home or Away" ||
      outcome.side === "Home" ||
      outcome.side === "Under" ||
      outcome.side === "No"
  );

  let outcome0MoreExpectedValue = false;
  let outcome1MoreExpectedValue = false;
  if (outcome0.expectedValues && outcome1.expectedValues) {
    const outcome0Ev =
      outcome0.expectedValues[evCalculationMethod] || -Infinity;
    const outcome1Ev =
      outcome1.expectedValues[evCalculationMethod] || -Infinity;
    outcome0MoreExpectedValue = outcome0Ev >= outcome1Ev;
    outcome1MoreExpectedValue = outcome1Ev > outcome0Ev;
  }

  const [selectedIncorrectBook, setSelectedIncorrectBook] = useState(
    outcome0.moreExpectedValue
      ? outcome0.book
      : outcome1.moreExpectedValue
      ? outcome1.book
      : undefined
  );

  let { isOverUnder, isPlayer, friendlyName, isYesNo, isMoneyline, isSpread } =
    typeData;

  if (isPlayer) {
    friendlyName = friendlyName.replace("%PLAYER%", player);
  }
  if (friendlyName.includes("%HOMETEAM%")) {
    friendlyName = friendlyName.replace("%HOMETEAM%", event.homeParticipant);
  }
  if (friendlyName.includes("%AWAYTEAM%")) {
    friendlyName = friendlyName.replace("%AWAYTEAM%", event.participants[0]);
  }

  const d = new Date(event.startTime);

  useEffect(() => {
    if (onWhoseWrongChange) onWhoseWrongChange(selectedIncorrectBook);
  }, [selectedIncorrectBook, onWhoseWrongChange]);

  const competitorName0 = buildCompetitorName(
    isOverUnder,
    isYesNo,
    outcome0.side,
    participants,
    0
  );
  const competitorName1 = buildCompetitorName(
    isOverUnder,
    isYesNo,
    outcome1.side,
    participants,
    1
  );

  return (
    <div
      className={styles.market}
      style={{ borderColor: themeTokens.colorBgLayout }}
    >
      <div
        className={styles.marketHeader}
        style={{
          borderBottom: middle ? "3px solid gold" : "",
          background: themeTokens.colorBgLayout,
          color: themeTokens.colorTextSecondary,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div>{friendlyName}</div>
          {(isOverUnder || isPlayer || isYesNo) && (
            <div className={styles.teams}>
              {`${participants[0]} @ ${participants[1]}`}
              {sport === "tennis" && <span> — {competitionName}</span>}
            </div>
          )}
          {(isMoneyline || isSpread) && sport === "tennis" && (
            <div className={styles.teams}>{competitionName}</div>
          )}
          {!live && (
            <div className={styles.teams}>
              {d.toLocaleTimeString([], {
                weekday: "short",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "2-digit",
              })}
            </div>
          )}
        </div>
        <div className={styles.headerButtonContainer}>
          {leagueEmoji && (
            <div>
              <span role="img" aria-label="ball">
                {leagueEmoji}
              </span>
            </div>
          )}
          {onPin && (
            <div className={styles.pinButton}>
              <Dropdown
                placement="bottomRight"
                menu={{
                  items: [
                    {
                      label: (
                        <button
                          className={styles.linkButton}
                          onClick={(e) => {
                            e.preventDefault();
                            onPin(participants);
                          }}
                        >
                          Game
                        </button>
                      ),
                      key: "0",
                    },
                    {
                      label: (
                        <button
                          className={styles.linkButton}
                          onClick={(e) => {
                            e.preventDefault();
                            onPin(participants, typeData.id, friendlyName);
                          }}
                        >
                          Market
                        </button>
                      ),
                      key: "1",
                    },
                  ],
                }}
                trigger={["click"]}
              >
                <PushpinOutlined
                  onClick={(e) => {
                    if (e.detail > 1) onPin(participants); //double or triple click
                  }}
                />
              </Dropdown>
            </div>
          )}
          {onHide && (
            <div className={styles.hideButton}>
              <Dropdown
                placement="bottomRight"
                menu={{
                  items: [
                    {
                      label: (
                        <button
                          className={styles.linkButton}
                          onClick={(e) => {
                            e.preventDefault();
                            onHide(participants);
                          }}
                        >
                          Game
                        </button>
                      ),
                      key: "0",
                    },
                    {
                      label: (
                        <button
                          className={styles.linkButton}
                          onClick={(e) => {
                            e.preventDefault();
                            onHide(participants, typeData.id, friendlyName);
                          }}
                        >
                          Market
                        </button>
                      ),
                      key: "1",
                    },
                  ],
                }}
                trigger={["click"]}
              >
                <EyeInvisibleOutlined
                  onClick={(e) => {
                    if (e.detail > 1) onHide(participants); //double or triple click
                  }}
                />
              </Dropdown>
            </div>
          )}
          {onAlert && betType && (
            <div>
              <NotificationOutlined
                onClick={() => {
                  onAlert({
                    player,
                    typeData,
                    event,
                    bet0,
                    bet1,
                    largeNumber,
                    smallNumber,
                    outcomes,
                    sport,
                    middle,
                    profitDollars: largeNumber.includes("$")
                      ? largeNumber
                      : smallNumber,
                    profitPercent: largeNumber.includes("$")
                      ? smallNumber
                      : largeNumber,
                  });
                }}
              />
            </div>
          )}
          {onWhoseWrongChange && (
            <div className={styles.whoseWrong}>
              WHOSE
              <br />
              WRONG?
            </div>
          )}
        </div>
      </div>
      <div
        className={styles.marketBody}
        style={{
          backgroundColor: highlightColor,
          color: themeTokens.colorText,
        }}
      >
        <div className={styles.profit}>
          <div>{largeNumber}</div>
          <div
            className={styles.smallNumber}
            style={{ color: themeTokens.colorTextSecondary }}
          >
            {smallNumber}
          </div>
        </div>
        <div className={styles.competitorsContainer}>
          <div className={styles.competitorContainer}>
            <div className={styles.competitorName}>
              <div>{competitorName0}</div>
              {bet1 && (
                <div className={styles.betAmount}>${bet1.toFixed(2)}</div>
              )}
            </div>
            <Odds
              typeData={typeData}
              outcome={outcome0}
              showExpectedValue={showExpectedValue}
              showOtherOdds={showOtherOdds}
              evCalculationMethod={evCalculationMethod}
              expectedValueCalcBooksMap={expectedValueCalcBooksMap}
              moreExpectedValue={outcome0MoreExpectedValue}
              onHideOutcome={(length) => {
                onHideOutcome(
                  participants,
                  typeData.id,
                  friendlyName,
                  outcome0,
                  length
                );
              }}
              onHideMarket={
                onHide
                  ? () => onHide(participants, typeData.id, friendlyName)
                  : undefined
              }
              onHideGame={onHide ? () => onHide(participants) : undefined}
            />
            {onWhoseWrongChange && (
              <Checkbox
                className={styles.whoseWrongCheckbox}
                onChange={(e) => {
                  if (e.target.checked) setSelectedIncorrectBook(outcome0.book);
                  else setSelectedIncorrectBook(undefined);
                }}
                checked={selectedIncorrectBook === outcome0.book}
              />
            )}
          </div>
          <div className={styles.competitorContainer}>
            <div className={styles.competitorName}>
              <div>{competitorName1}</div>
              {bet0 && (
                <div className={styles.betAmount}>${bet0.toFixed(2)}</div>
              )}
            </div>
            <Odds
              typeData={typeData}
              outcome={outcome1}
              showExpectedValue={showExpectedValue}
              showOtherOdds={showOtherOdds}
              evCalculationMethod={evCalculationMethod}
              expectedValueCalcBooksMap={expectedValueCalcBooksMap}
              moreExpectedValue={outcome1MoreExpectedValue}
              onHideOutcome={(length) => {
                onHideOutcome(
                  participants,
                  typeData.id,
                  friendlyName,
                  outcome1,
                  length
                );
              }}
              onHideMarket={
                onHide
                  ? () => onHide(participants, typeData.id, friendlyName)
                  : undefined
              }
              onHideGame={onHide ? () => onHide(participants) : undefined}
            />
            {onWhoseWrongChange && (
              <Checkbox
                className={styles.whoseWrongCheckbox}
                onChange={(e) => {
                  if (e.target.checked) setSelectedIncorrectBook(outcome1.book);
                  else setSelectedIncorrectBook(undefined);
                }}
                checked={selectedIncorrectBook === outcome1.book}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Market;
